import { createStore } from 'vuex';

import { connectionStore } from '@/store/connection.store';
import { serviceStore } from "@/store/service.store";

export default createStore({
  modules: {
    connection: connectionStore,
    service: serviceStore
  },
});
