import styled from 'vue3-styled-components';
import { rem } from 'polished';
import tokens from '@/styles/tokens';
import { props } from './osd.shared';

const { colors } = tokens;
export const StOSD = styled('div', props)`
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: hidden;
  overflow-x: auto;

  .navigator {
    transform: translateX(-100%);
  }
`;

/**
 * Define the border style of the annotation frame using the background-image css rules
 * The other css rules return by this function are needed to place the background-image correctly
 * @param { number } service The service id
 */
const getBorderStyle = ({ service }: { service: number }): string => {
  const borderColor = service === 2 ? colors.info.DEFAULT : colors.primary.DEFAULT;
  const dashSize = rem(12);
  const fadingSize = rem(12);
  const spacingSize = rem(24);

  return `
    background-position:  0 0, 0 0, 100% 0, 0 100%;
    background-size: 4px calc(100% + ${ spacingSize }), calc(100% + ${ spacingSize }) 4px, 4px calc(100% + ${ spacingSize }) , calc(100% + ${ spacingSize }) 4px;
    background-repeat: no-repeat;
    background-image:
       repeating-linear-gradient(0deg, ${ borderColor }, ${ borderColor } ${ dashSize }, transparent ${ fadingSize }, transparent ${ spacingSize }), // left
       repeating-linear-gradient(90deg, ${ borderColor }, ${ borderColor } ${ dashSize }, transparent ${ fadingSize }, transparent ${ spacingSize }), // top
       repeating-linear-gradient(180deg, ${ borderColor }, ${ borderColor } ${ dashSize }, transparent ${ fadingSize }, transparent ${ spacingSize }), // right
       repeating-linear-gradient(270deg, ${ borderColor }, ${ borderColor } ${ dashSize }, transparent ${ fadingSize }, transparent ${ spacingSize }) // bottom
    ;
  `
}

export const StOSDOverlayBackground = styled('div', {
  active: {
    type: Boolean,
    default: false,
  },
  isHighlight: {
    type: Boolean,
    default: false,
  },
  service: {
    type: Number,
    default: 1
  }
})`
  position: relative;
  z-index: 0;
  transition: border-color .3s ease-in-out;

  ${getBorderStyle}

  &::before {
    z-index: -1;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  button {
    opacity: 0;
    transition: opacity .2s ease-in-out;
  }

  &:hover {
    button {
      opacity: 1;
    }
  }

  ${ ({ active }) =>
    active &&
    `
      &:not(.is-zoomed)::before {
        opacity: .5;
      }

      animation: boxBorderAnimation 1s infinite linear;

      @keyframes boxBorderAnimation {
          from { background-position:  0 0, -20px 0, 100% -20px, 0 100%; }
          to { background-position:  0 -20px, 0 0, 100% 0, -20px 100%; }
      }
    ` }
  ${ ({ isHighlight }) =>
    isHighlight &&
    `
      border-color: ${ colors.info.DEFAULT };
      &:not(.is-zoomed)::before {
        opacity: .5;
      }
    ` }
  &.is-zoomed {
    &::before {
      opacity: 0;
    }
  }
`;
