import { Module } from "vuex";
import { Service } from "@/entities/services/types";

export type ServiceStore = {
  service: Service['id'] | null,
}

/**
 * Store used for injected the id service for the OSD background overlay
 * Need to change the border color based of the selected service
 * @see @/core/components/OSD/osd.styled.ts StOSDOverlayBackground
 *
 * The store is set :
 * @see @/teams/visionair/components/InspectionViewer/InspectionViewer
 */
export const serviceStore: Module<ServiceStore, null> = {
  namespaced: true,
  state: {
    service: null
  },
  getters: {
    service: (state) => state.service
  },
  actions: {
    setService: ({ state }, service) => {
      state.service = service
    }
  }
}
